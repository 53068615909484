<template>
  <main>
    <div class="content_wrapper">
      <div>

        <form  data-netlify="true" @submit.prevent="onSubmit" class="contact_wrapper form-around">

        <h3 class="contact_title">CONTACTEZ NOUS</h3>
        <p class="contact-infos">Pour toute demande concernant cette offre, vous pouvez nous contacter depuis le formulaire de contact ci-dessous :							
				  <br><br><small>Tous les champs marqués d'un astérisque sont obligatoires.</small>
        </p>

        <div class="contact_form">
        <Form
          :class="'form_step' + step"
          id="monform"
          :form_id="form_id"
          :server="server"
          :multiColumn="true"
          v-on:results="getResult"
          v-on:step-up="checkStep"
          v-on:step-down="downStep"
          v-on:form_submit_loading="loading"
          @on_error="viewError"
          :hideError="hiderror"
          :relay="{ operation_name: sujet }"
        />
        </div>

           <div class="cnil">Données personnelles : informations complètes <a href="https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/politique.pdf" target="_blank">ici</a>. GROHE, en tant que responsable de traitement, met en œuvre un traitement de données à caractère personnel ayant pour finalité l'organisation et la gestion d'une opération Promotionnelle..
Les données traitées dans l'intérêt légitime de GROHE sont indispensables  à ce traitement et sont  utilisées par les services concernés de GROHE et le cas échéant de ses sous-traitants. Les données sont conservées pendant une durée de 3 ans à compter de la fin de l'offre promotionnelle ou tant que vous n'avez pas retiré votre consentement. Vous bénéficiez, d'un droit d'interrogation, d'accès, de rectification, d'un droit à la portabilité, à la limitation du traitement et d'opposition. Vous pouvez adresser vos demandes à GROHE via l'adresse email : dataprotection_fr@grohe.com pour l'exercice de vos droits sur le traitement. Si vous estimez que vos droits ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL dont les coordonnées figurent à l'adresse internet <a href="https://www.cnil.fr" target="_blank">https://www.cnil.fr</a>.</div>
        </form>

      </div>
      <br>
    </div>
  </main>
</template>
<script>
var consts = require("../config");

export default {
  name: "form",
  data: function() {
    return {
      sujet : undefined,
      step: 0,
      hiderror: true,
      form_id: consts.CONTACT_ID,
      server: consts.SERVER_URL,
    };
  },
  created() {
    if(!process.env.MJ_EMAIL_SUBJECT) {
      this.sujet = '210224 - Offre Grohe Showroom - Nouvelle demande'
    } else {
      this.sujet =process.env.VUE_APP_MJ_EMAIL_SUBJECT
    }
  },
  methods: {
    loading(value) {
      if (value === true) {
        this.$swal({
          title: "Envoi en cours",
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    checkStep() {
      this.step = this.step+1;
    },
    downStep() {
      this.step = this.step-1;
    },
    getResult(result) {

      console.log('resultat envoi du mail', result)

      this.$swal({
        type: "success",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        confirmButtonColor: "#C94330",
        title: 'Merci',
        html:
          "Nous avons bien reçu votre demande de contact.",
      }).then(function () {
           window.location.href = "/";
      });

    },
    viewError(err) {
      console.log(err);

      this.$swal({
        confirmButtonText: "OK",
        confirmButtonColor: "#C94330",
        title: "Désolé, une erreur est survenue.",
        text: err[0],
      })
    },
  }
};
</script>